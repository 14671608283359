import React, { useState, ChangeEvent, MouseEvent, useEffect } from "react";
import "../assets/scss/traineeDetails.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseById } from "../slices/courseSlice"; // Adjust the import path as needed
import {
  updateFormData,
  updateErrorData,
  submitForm,
} from "../slices/traineeDetailsSlice"; // Adjust the import path as needed
import { RootState, AppDispatch } from "../store/store"; // Import AppDispatch and RootState

const TraineeDetails = () => {
  const dispatch = useDispatch<AppDispatch>(); // Type dispatch as AppDispatch
  // Redux state
  const course = useSelector((state: RootState) => state.course.course);

  const { traineeId, status, formData, errors } = useSelector(
    (state: RootState) => state.traineeDetails
  );

  const { courseId } = useParams<{ courseId: string }>();

  const [appliedCoupon, setAppliedCoupon] = useState<string>("");

  useEffect(() => {
    if (courseId) {
      console.log(courseId);

      // Dispatch the async thunk only if course hasn't been fetched yet or loading is in progress
      if (!course || course.course_id !== courseId) {
        dispatch(fetchCourseById(courseId)); // Dispatch to fetch the course
      }
    }
  }, [courseId, dispatch]); // Only depend on courseId and dispatch

  useEffect(() => {
    if (course && course.amount) {
      // Assuming you want to use course data (e.g., `course.amount`) to update trainee details
      // Set the amount based on the course data
      dispatch(
        updateFormData({
          ...formData,
          amount: course.amount,
          courseId: courseId,
          courseName: course.courseName,
        })
      );
    }
  }, [course, dispatch]); // Update the form data when course changes

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ ...formData, [name]: value }));
  };

  const applyCoupon = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!formData.couponCode.trim()) {
      dispatch(
        updateErrorData({
          ...errors,
          ["couponCode"]: "Please enter a coupon code.",
        })
      );
      return;
    }

    if (formData.couponCode === "DISCOUNT50") {
      setAppliedCoupon(formData.couponCode);
      //setTotalAmount((prev) => prev * 0.5); // Apply 50% discount
    } else {
      dispatch(
        updateErrorData({
          ...errors,
          ["couponCode"]: "Invalid coupon code.",
        })
      );
    }
  };

  const handlePaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedPaymentType = e.target.value as "Full Payment" | "Deposit";

    // Update formData in a single dispatch to avoid multiple updates
    dispatch(
      updateFormData({
        ...formData,
        typeofpayment: selectedPaymentType, // Set the selected payment type
        amount:
          selectedPaymentType === "Deposit"
            ? course?.depositAmount
            : course?.amount, // Set the total amount based on the payment type
      })
    );
  };

  const validateForm = () => {
    const newErrors = {
      fullNameError: "",
      email: "",
      phoneNumber: "",
      trainingDate: "",
    };
    let isValid = true;

    if (!formData.fullName.trim()) {
      newErrors.fullNameError = "Full name is required.";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email address is required.";
      isValid = false;
    }

    const phoneRegex = /^\d+$/;
    if (
      !formData.phoneNumber.trim() ||
      !phoneRegex.test(formData.phoneNumber)
    ) {
      newErrors.phoneNumber =
        "Valid phone number is required (e.g., 44 1234567890).";
      isValid = false;
    }

    if (!formData.trainingDate) {
      newErrors.trainingDate = "Please select a training date.";
      isValid = false;
    }
    dispatch(
      updateErrorData({
        ...errors,
        ...newErrors,
      })
    );
    return isValid;
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (status === "succeeded" && traineeId) {
      // Navigate to checkout page when form submission is successful
      navigate("/checkout/" + traineeId);
    }
  }, [status, navigate]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const result = dispatch(submitForm(formData));
      if (status == "succeeded") {
        navigate("/checkout"); // Redirect to checkout page on success
      } else {
        // Handle the error (if needed)
        console.error("Form submission failed:", result);
      }
    }
  };

  return (
    <div className="trainee-main">
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="orderSummary">{course?.courseName}</div>
        <div className="hlineMainMobile">
          <div className="leftFieldsWidthMobile"></div>
        </div>
        <div className="paymentMainSection">
          <div className="leftPaymentSection">
            <div className="traineeMainSection">
              <div className="leftPaymentHeadings">Personal Details</div>
              <div className="traineeFieldDiv">
                <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                {errors.fullNameError && (
                  <div className="errorText">{errors.fullNameError}</div>
                )}
              </div>
              <div className="traineeFieldDiv">
                <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email Address (info@lokem.co.uk)"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <div className="errorText">{errors.email}</div>
                )}
              </div>
              <div className="traineeFieldDiv">
                <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number (e.g 44 1234567890)"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                {errors.phoneNumber && (
                  <div className="errorText">{errors.phoneNumber}</div>
                )}
              </div>
              <div className="traineeFieldDiv">
                <select
                  name="trainingDate"
                  id="trainingDate"
                  className="contactSelect interest-select"
                  value={formData.trainingDate}
                  onChange={handleInputChange}
                >
                  <option value="">Please select training date</option>
                  {course?.trainingDate.split("|").map((date, index) => (
                    <option value={date}>{date}</option>
                  ))}
                </select>
                {errors.trainingDate && (
                  <div className="errorText">{errors.trainingDate}</div>
                )}
              </div>
              <div className="hlineMain">
                <div className="hline leftFieldsWidth"></div>
              </div>
            </div>
            <div className="scheduleMain">
              <div className="leftPaymentHeadings">
                Payment Schedule
                <div className="nonRefundable">
                  This payment is non refundable
                </div>
              </div>
              <div className="typeOfPaymentDiv">
                <span>
                  <input
                    type="radio"
                    name="typeOfPayment"
                    id="fullPayment"
                    value="Full Payment"
                    className="typeOfRadioField"
                    checked={formData.typeofpayment === "Full Payment"}
                    onChange={handlePaymentChange} // Correct onChange handler for the radio button
                  />
                  <label
                    htmlFor="fullPayment"
                    className="typeOfPaymentLabel fullPaymentLabel"
                    onClick={() =>
                      handlePaymentChange({
                        target: { value: "Full Payment" },
                      } as ChangeEvent<HTMLInputElement>)
                    } // Manually triggering the handler on label click
                  >
                    Full Payment (£{course?.amount})
                  </label>
                </span>
              </div>

              <div className="typeOfPaymentDiv">
                <span>
                  <input
                    type="radio"
                    name="typeOfPayment"
                    id="deposit"
                    value="Deposit"
                    className="typeOfRadioField"
                    checked={formData.typeofpayment === "Deposit"}
                    onChange={handlePaymentChange} // Correct onChange handler for the radio button
                  />
                  <label
                    htmlFor="deposit"
                    className="typeOfPaymentLabel depositLabel"
                    onClick={() =>
                      handlePaymentChange({
                        target: { value: "Deposit" },
                      } as ChangeEvent<HTMLInputElement>)
                    } // Manually triggering the handler on label click
                  >
                    Deposit (£{course?.depositAmount})
                  </label>
                </span>
              </div>

              <div className="hlineMain">
                <div className="hline leftFieldsWidth"></div>
              </div>
            </div>
            <div className="traineeMainSection">
              <div className="leftPaymentHeadings">Coupon</div>
              <div className="applyCouponDiv" style={{ textAlign: "left" }}>
                <div className="totalText">
                  <input
                    type="text"
                    name="couponCode"
                    id="couponCode"
                    placeholder="Coupon"
                    className="couponField"
                    value={formData.couponCode}
                    onChange={handleInputChange}
                  />
                  <input
                    type="hidden"
                    name="appliedCoupon"
                    id="appliedCoupon"
                    value={appliedCoupon}
                  />
                  {errors.couponCode && (
                    <span className="errorText">{errors.couponCode}</span>
                  )}
                </div>
                <div className="applyLinkDiv">
                  <a
                    href="javascript:void(0);"
                    id="applyCoupon"
                    onClick={applyCoupon}
                  >
                    Apply Coupon
                  </a>

                  <a
                    href="javascript:void(0);"
                    id="removeCoupon"
                    style={{ display: appliedCoupon ? "block" : "none" }}
                  >
                    Remove Coupon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="rightPaymentSection">
            <div className="includedInPurchase">
              <div className="rightPaymentHeadings"> Included In Purchase</div>
              <ul className="trainingIncludes">
                <li>
                  3 months Business analyst program
                  <br />
                  {course?.trainingDate?.split("|")[0]} 10:00 - 17:00 GMT
                </li>
                <li>3 months remote work experience in Scrum environment</li>
                <li>
                  Access to Scrum resources and training from agile coaches
                </li>
                <li>Certificate of attendance</li>
              </ul>
            </div>
            <div className="paymentAmountMain">
              <div className="amountHeading">Amount</div>
              <div className="totalPayment totalHeading">
                <div>
                  Total Amount &nbsp;{" "}
                  <span id="totalAmountDiv">£{formData.amount}</span>
                </div>
              </div>

              <div className="lf-use-code-main">
                Use code <span className="lf-coupon-code">AGILE50</span> for 50%
                off
              </div>

              <div className="makePaymentDiv">
                <button className="proceedPaymentBtn" id="submit">
                  <div className="spinner hidden" id="spinner"></div>
                  <span id="button-text lokem-button">Proceed to Payment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TraineeDetails;
