import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom"; // Use `useNavigate` instead of `useHistory`
import "../assets/scss/checkout.scss";

const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Initialize `useNavigate` to handle page navigation

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // No need for return_url if using redirect: 'if_required'
        return_url: `${process.env.REACT_APP_URL}/CourseConfirmation`,
      },
      //redirect: "if_required", // This allows us to handle the redirect manually
    });

    if (result.error) {
      // Handle error (e.g., invalid card, network issues, etc.)
      console.error(result.error.message);
      navigate("/failure"); // Navigate to failure page
    } else {
      // Handle success
      if (result.paymentIntent.status === "succeeded") {
        navigate("/CourseConfirmation"); // Navigate to success page
      } else {
        navigate("/failure"); // Navigate to failure page
      }
    }
  };

  return (
    <div className="checkout-wrapper">
      <div className="checkout-content">
        <div>
          <h2 className="checkout-heading">Payment Details</h2>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            id="payment-form"
            className="checkout-form"
          >
            <PaymentElement />
            <button className="payment-button">Make Payment £{amount}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
