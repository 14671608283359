import React, { useState } from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import "../assets/scss/footer.scss";
import logo from "../assets/images/lokemfooterlogo.png";
import HomeRequest from "../pages/HomeRequest";

const Footer: React.FC = () => {
  const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const getCookie = (name: string) => {
    const cookies = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name));
    return cookies ? cookies.split("=")[1] : "";
  };

  return (
    <div className="footer-main">
      <footer className="footer">
        <div className="footer-content">
          <div className="menu-items-footer-container">
            <ul className="menu-items-footer" id="menu-items-footer">
              <li>
                <a href="#">Our Trainings</a>
              </li>
              <li>
                <a href="/aboutUs">About Us</a>
              </li>
              <li>
                <a href="/blogs">Blogs</a>
              </li>
              <li>
                <a href="/testimonial">Testimonials</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
              <li>
                <a href="/contactForm">Contact Us</a>
              </li>
              <li className="login-click">
                <a href="/login">Login</a>
              </li>
            </ul>
            <div className="social-media-container">
              <ul className="social-media-icons" id="social-media-icons">
                <li>
                  <a
                    href="https://www.facebook.com/lokem/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa social-icon"
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/lo.kem/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa social-icon"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/lokemuk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa social-icon"
                  >
                    <FaTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="logo-content-footer-conatiner">
            <div className="logo-content-footer">
              <div className="callBackMain">
                <HomeRequest
                  buttonText="Request a Call Back"
                  showcourseField={false}
                />
              </div>

              <div className="logo-img-footer-container">
                <img className="logo-img-footer" src={logo} alt="Lokem Logo" />
              </div>
              <div className="logo-text-footer">
                <p>
                  Lokem are experts in Agile. We provide training and consulting
                  to organisations and individuals.
                </p>
                <p>
                  Our consultants and training workshops are delivered by expert
                  Agile consultants experienced in working with various clients
                  globally.
                </p>
              </div>
            </div>
            <div className="address-footer">
              <p>
                Kemp House 160, City Road
                <br /> London, EC1V 2NX
                <br /> Phone: +44(0) 20 3488 1904
              </p>
            </div>
          </div>
          <div className="copyRightText" id="copyRightText">
            Copyright © 2025
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
