import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface ContactFormState {
  formData: {
    name: string;
    email: string;
    contactNumber: string;
    course: string;
    userType: string;
  };
  errors: {
    name: string;
    email: string;
    contactNumber: string;
    course: string;
    userType: string;
  };
  responseMessage: string;
  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: ContactFormState = {
  formData: {
    name: "",
    email: "",
    contactNumber: "",
    course: "",
    userType: "individual", // Assuming userType is always 'individual'
  },
  errors: {
    name: "",
    email: "",
    contactNumber: "",
    course: "",
    userType: "",
  },
  responseMessage: "",
  status: "idle",
};

export const submitContactForm = createAsyncThunk(
  "contactForm/submit",
  async (formData: ContactFormState["formData"], { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/contact`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data.error);
      }
      return data.message;
    } catch (error) {
      return rejectWithValue("An error occurred while submitting the form.");
    }
  }
);

const contactSlice = createSlice({
  name: "contactForm",
  initialState,
  reducers: {
    updateResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialState.formData; // Reset formData to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        console.log("fulfilled");
        state.responseMessage = action.payload;
        state.formData = initialState.formData; // Reset form data
        state.status = "succeeded";
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.responseMessage = action.payload as string;
        state.status = "failed";
      });
  },
});

export const {
  updateFormData,
  setErrors,
  resetFormData,
  updateResponseMessage,
} = contactSlice.actions;
export default contactSlice.reducer;
