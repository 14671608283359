// src/slices/traineeDetailsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// Define the Trainee interface
interface TraineeDetailsState {
  formData: {
    fullName: string;
    email: string;
    phoneNumber: string;
    trainingDate: string;
    typeofpayment: string;
    couponCode: string;
    courseName: string;
    amount: number;
    courseId: string;
  };
  errors: {
    fullNameError: string;
    email: string;
    phoneNumber: string;
    trainingDate: string;
    typeofpayment: string;
    couponCode: string;
  };
  status: "loading" | "succeeded" | "failed" | null;
  message: string | null;
  error: string | null;
  traineeId: string;
  country: number;
}

const initialState: TraineeDetailsState = {
  formData: {
    fullName: "",
    email: "",
    phoneNumber: "",
    trainingDate: "",
    typeofpayment: "Full Payment",
    couponCode: "",
    courseName: "",
    amount: 0,
    courseId: "",
  },
  errors: {
    fullNameError: "",
    email: "",
    phoneNumber: "",
    trainingDate: "",
    typeofpayment: "",
    couponCode: "",
  },
  status: null, // loading, succeeded, failed
  message: null, // message returned from API on success
  error: null,
  traineeId: "",
  country: 1,
};

// Thunks for async actions

export const fetchCourseById = createAsyncThunk(
  "traineeDetails/fetchCourseById",
  async (courseId: string, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/courses/${courseId}`
      );
      if (!response.ok) throw new Error("Failed to fetch course");
      return await response.json();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const submitForm = createAsyncThunk(
  "employee/submit",
  async (formData: TraineeDetailsState["formData"], { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/traineeDetails`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data.error);
      }
      return data;
    } catch (error) {
      return rejectWithValue("An error occurred");
    }
  }
);

const traineeDetailsSlice = createSlice({
  name: "traineeDetails",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    updateErrorData: (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    setPaymentType: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    applyCoupon: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      // Handle submit form status
      .addCase(submitForm.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message; // Success message
        state.traineeId = action.payload.traineeId; // Success message
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string; // Error message
      })
      // Handle fetch course by ID status
      .addCase(fetchCourseById.pending, (state) => {
        // Optionally handle pending state for fetching course
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        // Handle the successful response for fetching course
      })
      .addCase(fetchCourseById.rejected, (state, action) => {
        // Handle error when fetching course
      });
  },
});

export const { applyCoupon, updateFormData, setPaymentType, updateErrorData } =
  traineeDetailsSlice.actions;

export default traineeDetailsSlice.reducer;
