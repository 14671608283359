// src/slices/coursesSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface PaymentDetailsState {
  clientSecret: string;
  amount: number;
}

const initialState: PaymentDetailsState = {
  clientSecret: "",
  amount: 0,
};

// Define the asynchronous thunk action to fetch a single course by ID using fetch API
export const initiatePayment = createAsyncThunk(
  "",
  async (traineeId: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/payment/initiate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ traineeId }), // Send traineeId in the request body
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch payment");
    }
    const data = await response.json();
    return data;
  }
);

// Create the slice
const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiatePayment.pending, (state) => {})
      .addCase(initiatePayment.fulfilled, (state, action) => {
        state.clientSecret = action.payload.clientSecret;
        state.amount = action.payload.amount;
      })
      .addCase(initiatePayment.rejected, (state, action) => {});
  },
});

// Export the reducer
export default courseSlice.reducer;
