import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/businessAnalyst.scss";
import img1 from "../assets/images/ba-training1.jpg";
import img2 from "../assets/images/ba-training2.jpg";
import img3 from "../assets/images/ba-training3.jpg";
import img4 from "../assets/images/sm-experts.png";
import img5 from "../assets/images/sm-mindset.png";
import img6 from "../assets/images/sm-agility.png";
import img7 from "../assets/images/sm-delivery.png";
import img8 from "../assets/images/po-learn.png";

//Section 5
import img9 from "../assets/images/ba-journey-map.png";
import { useNavigate } from "react-router-dom";
import AdvCoupon from "../pages/AdvCoupon";

const BusinessAnalyst = () => {
  const navigate = useNavigate();
  const navigateToCourse = (courseId: string) => {
    navigate(`/course/${courseId}`);
  };
  return (
   <>
    <AdvCoupon/>
    <div className="businessAnalyst-main">
      {/* Banner */}
      <div className="top-page-ba">
      <div className="first-page-container-ba">
        <div className="first-page-heading-ba banner-heading lokem-headers-ba">
          Become a Certified Business Analyst
        </div>
      </div>
      <div className="training-dates-button-section-ba book-btn-position-ba">
        <button
          className="book-place-button-ba lokem-button-ba"
         data-course="1" onClick={() => navigateToCourse('2')}
        >
          BOOK NOW
        </button>
      </div>
    </div>


      {/* Section 2 */}
      <div className="train-your-main-ba training-overview-main-ba">
      <div className="max-width-div-ba">
        <div className="tyt-heading-main-ba">
          <div className="tyt-heading-ba tyt-heading-text-ba padding-top-ba">Training Overview</div>
        </div>
        <div className="overview-cols-main-ba">
          <div className="ba-overview-col1-ba">
            <div className="tyt-text-ba">
              <div className="each-para-div-ba">
                <div>
                  A Business Analyst is responsible for understanding the changes in business needs, as well as capturing, analysing and documenting requirements. They help to guide businesses in improving processes, products, services and software. They also support the communication and delivery of requirements to relevant stakeholders. 
                </div>
              </div>
              <div className="each-para-div-ba">
                <div>
                  Study with us and become a Business Analyst. Give your career a boost by embarking on a training course that employers around the world respect, value and, most importantly, need.
                </div>
              </div>
            </div>
          </div>
          <div className="ba-overview-col2-ba">
            <div className="tyt-text-ba">
              <div className="each-para-div-ba">
                <div>
                This training is for leaders, managers and other professionals seeking to gain in-depth knowledge on business analysis and learn how to their way of delivering projects.
                </div>
              </div>
              <div className="each-para-div-ba">
                <div>
                  Looking for a change in career? New Management? No prior IT knowledge or background? No problem! We will give you all the skills &amp; knowledge you need to succeed.
                </div>
              </div>
              <div className="each-para-div-ba">
                <div>
                  We coach, mentor and guide you to become qualified.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overview-cols-main-ba to-margin-ba">
          <div className="to-img-ba">
            <img className="course-overview-image1-ba" src={img1} alt="BA Image 1" />
          </div>
          <div className="to-img-ba">
            <img className="course-overview-image1-ba" src={img2} alt="BA Image 2" />
          </div>
          <div className="to-img-ba">
            <img className="course-overview-image1-ba" src={img3} alt="BA Image 3" />
          </div>
        </div>
      </div>
    </div>

      {/* Section 3*/}
      <div className="tobj-main-ba">
      <div className="tyt-heading-ba tyt-heading-text-ba tobj-heading-ba">Training Objectives</div>
      <div className="tobj-sub-ba">Students will learn how to:</div>
      <div className="tobj-col-main-ba tyt-text-ba tobj-color-ba">
        <div className="tobj-row-ba">
          <div className="tobj-col1-ba">
            <div className="tyt-sub-heading-ba tobj-color-ba">
              <img src={img4} className="tobj-img-ba" alt="Expertise" />
            </div>
            <div className="each-para-div-ba">
              <div>
                Identify and evaluate options for improving businesses.
              </div>
            </div>
          </div>
          <div className="tobj-col1-ba">
            <div className="tyt-sub-heading-ba tobj-color-ba">
              <img src={img5}className="tobj-img-ba" alt="Mindset" />
            </div>
            <div className="each-para-div-ba">
              <div>
                Develop the skill set of engaging with stakeholders to understand and respond to their requirements in changing business environments.
              </div>
            </div>
          </div>
        </div>
        <div className="tobj-row-ba">
          <div className="tobj-col1-ba">
            <div className="tyt-sub-heading-ba tobj-color-ba">
              <img src={img6} className="tobj-img-ba" alt="Agility" />
            </div>
            <div className="each-para-div-ba">
              <div>
                Make a difference in the agility of your current or future employers’ transformation through the correct execution of the Business Analyst role.
              </div>
            </div>
          </div>
          <div className="tobj-col1-ba">
            <div className="tyt-sub-heading-ba tobj-color-ba">
              <img src={img7} className="tobj-img-ba" alt="Delivery" />
            </div>
            <div className="each-para-div-ba">
              <div>
                Build the skills and knowledge required to support successful business change programmes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Section 4 */}
      <div className="train-your-main-ba training-overview-main-ba">
      <div className="max-width-div-ba">
        <div className="overview-cols-main-ba">
          {/* Overview Column 1 */}
          <div className="overview-col1-ba">
            <div className="tyt-heading-main-ba">
              <div className="tyt-heading-ba tyt-heading-text-ba">What Will You Learn?</div>
            </div>
            <div className="tyt-text-ba">
              <div className="each-para-div-ba padding-bottom-para-ba">
                <div>
                  Working on a project, and be able to apply theory to practice. You will constantly learn and develop
                  the essential skills of a Business Analyst such as business acumen, communication, elicitation, and
                  how to specify and verify requirements.
                </div>
              </div>
              <div className="each-para-div-ba">
                <div>
                  <div className="modules-covered-ba">
                    <div className="mc-heading-ba tyt-sub-heading-ba">Modules Covered</div>
                    <div>The roles and responsibilities of a Business Analyst</div>
                    <div>How to identify and evaluate options for improving business processes</div>
                    <div>
                      Effective requirement gathering techniques, such as: interviews, workshops, prototyping, and
                      scenarios
                    </div>
                    <div>How to effectively identify, analyse, and manage stakeholders</div>
                    <div>Making a business case and assessing feasibility</div>
                    <div>How to document, manage, validate, and translate requirements</div>
                    <div>Stakeholder management and collaboration techniques</div>
                    <div>
                      Identify and implement metrics to track value creation and successful product delivery
                    </div>
                  </div>
                  <div>
                    <div className="fac-course-ba tyt-sub-heading-ba">Who Facilitates The Course?</div>
                    <div>
                      Our training workshops are delivered by expert Agile consultants working at globally renowned
                      companies including Barclaycard, Lloyds, Selfridges &amp; Vodafone.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Overview Column 2 */}
          <div className="overview-col2-ba">
            <div>
              <div className="training-dates-main-ba tyt-text-ba">
                <div className="training-dates-text-ba tyt-sub-heading-ba">Training Dates</div>
                <div className="location-online-ba">Location: online</div>
                <div>Followed by a minimum of 3 months online training</div>
                <div className="package-includes-ba">
                  (Package also includes access to work experience opportunities)
                </div>
                <div className="training-dates-button-section-ba booknow-style-ba">
                  <link media="all" rel="stylesheet" href="css/limitedOffer.css" />
                  <div className="lf-use-code-main-ba">
                    Use code <span className="lf-coupon-code-ba">AGILE50</span> for 50% off
                  </div>
                  <button className="book-place-button-ba lokem-button-ba" data-course="2">
                    BOOK NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="wyl-img-main-ba">
              <img
                className="course-overview-image2-ba float-initial-ba"
                src={img8}
                alt="Course Overview"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Section 5 */}
      <div className=" po-journey-main">
    <img src={img9} className="po-Infograph" />
</div>
    </div>
    </>
  );
};

export default BusinessAnalyst;
