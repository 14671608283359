import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";
import { initiatePayment } from "../slices/paymentSlice"; // Adjust the import path as needed
import { useDispatch, useSelector } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentPage = () => {
  const { traineeId } = useParams(); // Fetch the traineeId from the URL
  const dispatch = useDispatch(); // Type dispatch as AppDispatch
  const { clientSecret, amount } = useSelector((state) => state.paymentDetails);
  useEffect(() => {
    if (traineeId) {
      dispatch(initiatePayment(traineeId));
    }
  }, [traineeId]); // Re-run when traineeId changes

  if (!clientSecret) {
    return <div>Loading...</div>; // Show loading state while waiting for clientSecret
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm amount={amount} />
    </Elements>
  );
};

export default PaymentPage;
