import React, { useState, useEffect } from "react";
import "../assets/scss/HomeRequest.scss";

interface CallbackFormProps {
  buttonText: string;
  showcourseField?: boolean;
}

const CallbackForm: React.FC<CallbackFormProps> = ({
  buttonText,
  showcourseField = true, // Default to true
}) => {
  // Local state for each form instance
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    course: "",
    userType: "individual", // Assuming userType is always 'individual'
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    course: "",
    userType: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState<
    "idle" | "loading" | "succeeded" | "failed"
  >("idle");
  const [showResponse, setShowResponse] = useState(false);

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "userType" ? "individual" : value, // Ensure userType is always 'individual'
    });

    // Clear errors for the specific field if any
    if (errors[name as keyof typeof errors]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Validate form before submission
  const validateForm = () => {
    const newErrors = {
      name: "",
      email: "",
      contactNumber: "",
      course: "",
      userType: "", // userType is always 'individual', no validation required
    };

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.contactNumber.trim() || !/^\d+$/.test(formData.contactNumber))
      newErrors.contactNumber = "Valid phone number is required.";
    if (showcourseField && !formData.course.trim())
      newErrors.course = "Please select a course.";

    setErrors(newErrors);

    // Return true if no errors, false if there are errors
    return Object.values(newErrors).every((error) => !error);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setStatus("loading");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/contact`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          setResponseMessage(data.error);
          setStatus("failed");
        } else {
          setResponseMessage(data.message);
          setStatus("succeeded");
          setFormData({
            name: "",
            email: "",
            contactNumber: "",
            course: "",
            userType: "individual",
          }); // Reset form data
        }
      } catch (error) {
        setResponseMessage("An error occurred while submitting the form.");
        setStatus("failed");
      }
    }
  };

  // Show response message for a few seconds
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (responseMessage) {
      setShowResponse(true);
      timer = setTimeout(() => {
        setShowResponse(false);
        setResponseMessage(""); // Clear response after 5 seconds
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [responseMessage]);

  return (
    <form className="callbackFormMain">
      <div className="contactMain">
        <div className="contactMainForm">
          <div className="contactFieldDiv">
            <input
              type="text"
              name="name"
              className="contactInput"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error-text">{errors.name}</div>}
          </div>
          <div className="contactFieldDiv">
            <input
              type="text"
              name="email"
              className="contactInput"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error-text">{errors.email}</div>}
          </div>
          <div className="contactFieldDiv">
            <input
              type="text"
              name="contactNumber"
              className="contactInput"
              placeholder="Contact Number"
              value={formData.contactNumber}
              onChange={handleChange}
            />
            {errors.contactNumber && (
              <div className="error-text">{errors.contactNumber}</div>
            )}
          </div>
          {showcourseField !== false && (
            <div className="contactFieldDiv">
              <select
                name="course"
                className="contactInput interest-select"
                value={formData.course}
                onChange={handleChange}
              >
                <option value="">Please select your interest</option>
                <option value="Scrum Master">SM Training</option>
                <option value="Business Analyst">BA Training</option>
                <option value="Product Owner">PO Training</option>
              </select>
              {errors.course && (
                <div className="error-text">{errors.course}</div>
              )}
            </div>
          )}
          <div className="contactFieldDiv">
            <button
              className="contactSubmitBtn"
              type="button"
              onClick={handleSubmit}
              disabled={status === "loading"}
            >
              {status === "loading" ? <div className="btnSpinner"></div> : null}
              <span className="buttonText">{buttonText}</span>
            </button>
          </div>
        </div>

        {showResponse && (
          <div id="contactMsg" className="contactMsg">
            {responseMessage}
          </div>
        )}
      </div>
    </form>
  );
};

export default CallbackForm;
