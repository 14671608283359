// src/slices/coursesSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the Course interface
export interface Course {
  course_id: string;
  courseName: string;
  amount: string;
  depositAmount: string;
  trainingDate: string;
  courseImage: string | null;
  postDate: string;
  currency: string;
  curSymbol: string;
}

// Define the initial state interface
interface CourseState {
  course: Course | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: CourseState = {
  course: null,
  loading: false,
  error: null,
};

// Define the asynchronous thunk action to fetch a single course by ID using fetch API
export const fetchCourseById = createAsyncThunk(
  "courses/fetchCourseById",
  async (courseId: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/courses/` + courseId
    );

    if (!response.ok) {
      throw new Error("Failed to fetch course");
    }

    const data = await response.json();
    return data; // Return the course data from the response
  }
);

// Create the slice
const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseById.pending, (state) => {
        state.loading = true; // Set loading to true when the request starts
        state.error = null; // Reset any previous errors
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.loading = false; // Set loading to false when request is done
        state.course = action.payload; // Store the course data in the state
      })
      .addCase(fetchCourseById.rejected, (state, action) => {
        state.loading = false; // Set loading to false when request fails
        state.error = action.error.message || "Failed to fetch course"; // Set error message
      });
  },
});

// Export the reducer
export default courseSlice.reducer;
