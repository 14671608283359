import React from "react";
import "../assets/scss/CourseConfirmation.scss";

// Define the type for the props
interface CourseConfirmationProps {
  height: number;
}

const CourseConfirmation: React.FC<CourseConfirmationProps> = ({ height }) => {
  return (
    <div
      className="course-confirmation-wrapper"
      style={{ height: `${height}px` }} // Apply dynamic height
    >
      <h1 className="course-confirm-message">
        Thank you for completing your online payment.
        <br />
        An email confirmation will be sent to you shortly.
      </h1>
    </div>
  );
};

export default CourseConfirmation;
